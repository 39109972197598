<template>
  <div class='main'>
    <Headers :class="isBanner ? 'white' : 'lin_g'" />

    <div class="views">
      <router-view />
    </div>

    <Footers />
  </div>
</template>

<script>

export default {
  data () {
    return {
      isBanner: false
    }
  },
  created () {
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop >= 600) {
        this.isBanner = true
      } else {
        this.isBanner = false
      }
    },
  },
}
</script>

<style scoped lang='scss'>
.main {
  min-width: 1440px;
  max-width: 1920px;
  height: 100%;
  margin: 0 auto;

  .lin_g {
    background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0.17) 100%);
  }

  .white {
    background-color: #fff;
  }

  .views {
    min-height: calc(100vh - 100px);
  }
}
</style>
